import React, { useState, useEffect } from "react";
import { useDispatch, useSelector  } from "react-redux";
import { Table, Pagination, Form } from "react-bootstrap";
import { getSummaryByUser } from '../../actions/index';
import { generateStrategyNameList, convertToKMB, dynamicRetailerimpactPriceFormat } from "../../helper";
import { CarretIconUp, CarretIconDown } from '../../components/Icons';

const UserSummaryTable = (props) => {
  const [userSummaryData, setUserSummaryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  let {selectedCustomerKey, selectedRetailerCurrency, dynamicPriceFormatting, defaultDailyUsageConfig } = useSelector((state) => state.globalReducer);
  let allStrategyDetails = useSelector((state) => state.strategyReducer.detailsForAllStrategies );
  let [selectedStrategies] = useState(generateStrategyNameList(allStrategyDetails));

  let {
    adoptionSelectedUsers,
    adoptionAdditionalParamaters,
    selectedDateRange,
    adoptionCustomDates,
    adoptionSelectedStrategies,
    userSummary,
    adoptionSelectedUseCases,
    retailerCurrency
  } = useSelector((state) => state.adoptionReducer);

  let retailerCurrencySign = retailerCurrency === undefined || retailerCurrency === '' || retailerCurrency === 'USD' ? selectedRetailerCurrency : '$'; 

  let responseHandler = (response) => {
  if(response?.data){
     if(response.data.responseData.length <= 0) {
      setLoading(false)
     } else {
        setUserSummaryData(response.data.responseData) 
        setTotalSize(response.data.responseData.length)
        setLoading(false)
    }
  } else {
    setLoading(false)
  }
  };

  let requestObject = () => {
    return {
      userNames: adoptionSelectedUsers ? adoptionSelectedUsers : [],
      strategies: adoptionSelectedStrategies ? adoptionSelectedStrategies : [],
      dropDownSelectionPeriod: selectedDateRange ? selectedDateRange : "Prior Week",
      additionalParametersInterval: adoptionCustomDates ? adoptionCustomDates : {},
      additionalParametersPoint: adoptionAdditionalParamaters,
      pageNo: pageNumber,
      totalRows: sizePerPage,
      useCase: adoptionSelectedUseCases,
      isCustomDateRange: selectedDateRange == "Custom Date Range" ? true : false,
      defaultDailyUsageConfig: defaultDailyUsageConfig ? defaultDailyUsageConfig : '',
      currency: retailerCurrency === undefined || retailerCurrency === '' ? 'USD' : retailerCurrency,
      customerKey: selectedCustomerKey
    };
  };
  useEffect(() => {
    if(userSummary && userSummary.responseData){
      setUserSummaryData(userSummary.responseData) 
      setTotalSize(userSummary.responseData.length)
      setLoading(false)
    }
  }, [userSummary]);
  useEffect(() => {
    setLoading(true)
    if (selectedDateRange) {
    dispatch(getSummaryByUser(requestObject(), responseHandler));
    }
  },[
    selectedDateRange,
    adoptionCustomDates,
    adoptionSelectedUsers,
    adoptionAdditionalParamaters,
    selectedStrategies,
    sizePerPage, 
    pageNumber,
    retailerCurrency,
    adoptionSelectedUseCases,
    adoptionSelectedStrategies
  ]);
  
  const estimatedRevenueFormatter = (cell) => {
    if(cell !== undefined && cell !== null && cell !== 'NaN') {
    return (
      <>
        {Number(cell) && dynamicPriceFormatting && dynamicPriceFormatting === 'dot' ? dynamicRetailerimpactPriceFormat(convertToKMB(Number(cell), retailerCurrencySign)) : Number(cell) ? convertToKMB(Number(cell), retailerCurrencySign) : retailerCurrencySign + cell}
      </>
    )
    } else {
      return `${retailerCurrencySign}0`
    }
  }
  const percentValue = (cell) => {
    if(cell !== undefined && cell !== null && cell !== 'NaN' && cell !== 'NA') {
      let finalVal = parseFloat(cell).toFixed(2);
      let finalValfixed = parseFloat(cell).toFixed(0);
      if(finalVal == 0) {
        return <span className="clr-grn"><CarretIconUp />{finalValfixed}%</span>
      } else if(finalVal > 0) {
        return <span className="clr-grn"><CarretIconUp />{finalVal}%</span>
      }  else {
        return  <span className="clr-rd"><CarretIconDown />{finalVal}%</span>
      }     
    } else {
      return  <span className="clr-grn"><CarretIconUp />0%</span>
    }  
  }
  const percentValuenew = (cell) => {
    return(<>{cell != undefined ? cell + '%' : '0%'}</>)
  }
  const actionTime = (cell) => {
    return(<>{cell == 1 ? cell + 'day' : cell != undefined ? cell + ' days' : '0 days'}</>)
  }

  // Handle page size change
  const handleSizePerPageChange = (e) => {
    setSizePerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page when size per page changes
  };

  // Sorting functions
  const [sortField, setSortField] = useState(''); 
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSort = (field) => {
    let order = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
  
    const sortedData = [...userSummaryData].sort((a, b) => {
      const valueA = a[field] !== undefined && a[field] !== null ? a[field].toString() : '';
      const valueB = b[field] !== undefined && b[field] !== null ? b[field].toString() : '';
  
      if (typeof a[field] === 'number') {
        return order === 'asc' ? a[field] - b[field] : b[field] - a[field];
      } else if (typeof a[field] === 'string' || typeof a[field] === 'number') {
        return order === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
      return 0;
    });
  
    setUserSummaryData(sortedData);
  };

  const handleSortIcon = (field) => {
    if (sortField === field) {
      return sortOrder === 'asc' 
        ? <i className="fas fa-sort-up ml-2 active"></i> 
        : <i className="fas fa-sort-down ml-2 active"></i>;
    }
    return <i className="fas fa-sort ml-2"></i>;
  };

  const pageSizeOptions = [10, 20, 30, 50];
  const totalPages = Math.ceil(totalSize / sizePerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setPageNumber(page); // Update the page number for pagination
  };

  const getPaginationItems = () => {
    const items = [];
    const visiblePages = 3; 

    const startPage = Math.max(1, currentPage - visiblePages);
    const endPage = Math.min(totalPages, currentPage + visiblePages);

    if (startPage > 1) {
      items.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }
      items.push(
        <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  const paginatedData = userSummaryData.slice((currentPage - 1) * sizePerPage, currentPage * sizePerPage);

  if (loading) {
    return (
      <div id="no-data-available" style={{ marginTop: "5%" }}>
        Loading....
      </div>
    );
  } else {
    return (
      <div className='alert_summarytable mt-5'>
        <div className="row ml-0">
          <div style={{fontSize: 18, marginBottom: 10,fontWeight: "bold" }}>
            Summary By User
          </div>
        </div>
        <Table bordered className='table_center mb-4'>
          <thead>
            <tr className='impactpagerow_first'>
              <th rowSpan="2" style={{ width: '100px' }} onClick={() => handleSort('userName')}>User{handleSortIcon('userName')}</th>
              <th rowSpan="2" style={{ width: '100px' }} onClick={() => handleSort('daysOfUsage')}>Days of usage{handleSortIcon('daysOfUsage')}</th>
              <th colSpan="3" style={{ width: '100px' }} >Usage %</th>
              <th colSpan="3" style={{ width: '100px' }}>AVG Actions Taken per day</th>
              <th colSpan="2" style={{ width: '100px' }}>Actions Taken</th>
              <th colSpan="1" style={{ width: '100px' }}>Unresolved Actions</th>
              <th colSpan="2" style={{ width: '100px' }}>Resolved Actions</th>
              <th colSpan="2" style={{ width: '100px' }}>Sales Impact</th>
              <th colSpan="6" style={{ width: '100px' }}>Effectiveness</th>
            </tr>
            <tr>
              <th style={{ width: '100px' }} onClick={() => handleSort('dailyUsageCurrPercent')}>Current{handleSortIcon('dailyUsageCurrPercent')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('dailyUsagePOPPercent')}>PoP{handleSortIcon('dailyUsagePOPPercent')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('dailyUsageYOYPercent')}>YoY{handleSortIcon('dailyUsageYOYPercent')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('avgActionTakenperDayCur')}>Current{handleSortIcon('avgActionTakenperDayCur')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('avgActionTakenperDayPOP')}>PoP{handleSortIcon('avgActionTakenperDayPOP')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('avgActionTakenperDayYOY')}>YoY{handleSortIcon('avgActionTakenperDayYOY')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('actionedEstimatedValue')}>Estimated Value{handleSortIcon('actionedEstimatedValue')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('avgActionTime')}>Avg Action Time{handleSortIcon('avgActionTime')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('unResolvedEstimatedValue')}>Estimated Value{handleSortIcon('unResolvedEstimatedValue')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('resolvedEstimatedValue')}>Estimated Value{handleSortIcon('resolvedEstimatedValue')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('avgResolutionTime')}>Avg Resol. time{handleSortIcon('avgResolutionTime')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('salesImpact')}>Value{handleSortIcon('salesImpact')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('salesImpactPercent')}>PoP{handleSortIcon('salesImpactPercent')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('effectiveNessVSTriggered')}>vs.Triggered{handleSortIcon('effectiveNessVSTriggered')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('effectiveNessVSTriggeredPOP')}>PoP{handleSortIcon('effectiveNessVSTriggeredPOP')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('effectiveNessVSTriggeredYOY')}>YoY{handleSortIcon('effectiveNessVSTriggeredYOY')}</th>
              <th style={{ width: '100px' }} onClick={() => handleSort('effectiveNessVSActioned')}>vs.Actioned{handleSortIcon('effectiveNessVSActioned')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('effectiveNessVSActionedPOP')}>PoP{handleSortIcon('effectiveNessVSActionedPOP')}</th>
              <th style={{ width: '90px' }} onClick={() => handleSort('effectiveNessVSActionedYOY')}>YoY{handleSortIcon('effectiveNessVSActionedYOY')}</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.length > 0 ? <>
            {paginatedData.map((row, index) => (
              <tr key={index}>
                <td>{row?.userName}</td>
                <td>{row?.daysOfUsage}</td>
                <td>{percentValuenew(row?.dailyUsageCurrPercent)}</td>
                <td>{percentValue(row.dailyUsagePOPPercent)}</td>
                <td>{percentValue(row?.dailyUsageYOYPercent)}</td>
                <td>{row.avgActionTakenperDayCur}</td>
                <td>{percentValue(row.avgActionTakenperDayPOP)}</td>
                <td>{percentValue(row?.avgActionTakenperDayYOY)}</td>
                <td>{estimatedRevenueFormatter(row.actionedEstimatedValue)}</td>
                <td>{actionTime(row?.avgActionTime)}</td>
                <td>{estimatedRevenueFormatter(row.unResolvedEstimatedValue)}</td>
                <td>{estimatedRevenueFormatter(row?.resolvedEstimatedValue)}</td>
                <td>{actionTime(row?.avgResolutionTime)}</td>
                <td>{estimatedRevenueFormatter(row.salesImpact)}</td>
                <td>{percentValue(row?.salesImpactPercent)}</td>
                <td>{percentValuenew(row.effectiveNessVSTriggered)}</td>
                <td>{percentValue(row?.effectiveNessVSTriggeredPOP)}</td>
                <td>{percentValue(row?.effectiveNessVSTriggeredYOY)}</td>
                <td>{percentValuenew(row.effectiveNessVSActioned)}</td>
                <td>{percentValue(row?.effectiveNessVSActionedPOP)}</td>
                <td>{percentValue(row?.effectiveNessVSActionedYOY)}</td>
              </tr>
            ))}</>: <tr> <td colSpan="21">No User Data Available</td></tr>}
          </tbody>
        </Table>
        {userSummaryData?.length > 0 &&
        <div className="d-flex justify-content-between align-items-center mt-0">
        <Form.Group controlId="sizePerPageSelect">
          <Form.Control
            as="select"
            value={sizePerPage}
            onChange={handleSizePerPageChange}
            id='pageDropDown'
            className="pageDropdownoption"
          >
            {pageSizeOptions.map(size => (
              <option key={size} value={size}>{size}</option>
            ))}
          </Form.Control>
          <Form.Label>Showing rows {((currentPage - 1) * sizePerPage) + 1} to {Math.min(currentPage * sizePerPage, totalSize)} of {totalSize}</Form.Label>
        </Form.Group>
        
        <Pagination className="d-flex justify-content-end mb-4">
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          />
          {getPaginationItems()}
          <Pagination.Next
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </Pagination>
      </div> }
        
      </div>
    )
  }
}

export default UserSummaryTable;