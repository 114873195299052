import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { getEmbedToken } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import BarLoader from "react-spinners/BarLoader";
import { addCommonActivityCaptureDetail } from '../../helper';

const NewRecommendationModal = (props) => {
    let dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [embedPBIToken, setEmbedPBIToken] = useState('');
    const [reportId, setReportId] = useState(props.reportId);
    let activeRowForModel = props.activeRowForModel;
    let sosAlertname = props?.sosAlertname;
    const [recommendationName, setRecommendationName] = useState(props.recommendationName);
    let { selectedRecommendation } = useSelector((state) => state.recommendationsReducer);
    let { selectedGlobalFilters } = useSelector((state) => state.globalReducer);
    let { detailsForAllStrategies, selectedStrategies } = useSelector((state) => state.strategyReducer)
    const getActivityInfo =()=>{
        let estimatedValue;
        props.listOfStrategiesInfo?.length && props.listOfStrategiesInfo.map((item) => {
            if (item.recommendationName === recommendationName)
                estimatedValue = item.estimatedValue;
        })
        let activityInfo = {
            page: "Recommendations Usage Summary",
            strategies: recommendationName,
            listOfProducts: [{
                product_id: selectedRecommendation?.productId,
                location: selectedRecommendation?.fcNameId,
                estimatedValue: estimatedValue,
                dateOfRecommendation: selectedRecommendation?.dateOfRecommendation
            }],
            globalFilters: selectedGlobalFilters,
            strategyFilters: selectedStrategies
        }
        return activityInfo;
    }

    useEffect(() => {    
        setLoading(true);
        addCommonActivityCaptureDetail(getActivityInfo());
        dispatch(getEmbedToken({
            groupId: process.env.REACT_APP_GROUP_ID,
            dashboardId: reportId
        })).then((res) => {
            if (res?.data?.embedToken) {
                setEmbedPBIToken(res.data.embedToken);
                setLoading(false);
            }
        }).catch((error) => {
            console.error("Error fetching embed token: ", error);
            setLoading(false);
        });
    }, [reportId]);

    let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${process.env.REACT_APP_GROUP_ID}`;
    embedUrl = embedUrl.replace("watch?v=", "v/");
    let filterArr = [];
    const columnList = [];
    const tableList = [];
    const extraSettings = {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        hideErrors: false
    };
    const reportStyle = {
        height: "48rem"
    };
    if (recommendationName === 'Change In Sentiment') {
        tableList.push('Change in Sentiment', 'Sentiment Analysis')
        columnList.push({
            column: "Product ID",
            propsName: props.chosenProductId
        }, {
            column: "Customer Key",
            propsName: props.chosenCustomerKey
        });

        filterArr.push({
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: "Calendar Date",
                column: "Date Range",
            },
            operator: "In",
            values: ['Last 4 Weeks']
        });
    } else {
        if (recommendationName === 'Competitor Price Difference') {
            tableList.push(`${recommendationName} Detailed`);
        } else if (recommendationName === 'Coupon ROAS') {
            tableList.push('Coupon Codes');
        } else if (recommendationName === 'Keyword Efficiency') {
            tableList.push(`${recommendationName} Detailed`, `${recommendationName} Detailed Store`);
        }
        tableList.push(recommendationName);

        if (sosAlertname) {
            columnList.push({
                column: "Keyword",
                propsName: props.chosenKeyword
            }, {
                column: "Rank Type",
                propsName: props.chosenRankType
            }
            );
        } else {
            columnList.push({
                column: "Location",
                propsName: props.chosenLocation
            }, {
                column: "Product ID",
                propsName: props.chosenProductId
            }
            );
        }
        columnList.push({
            column: "Customer Key",
            propsName: props.chosenCustomerKey
        })
    }


    for (let i = 0; i < tableList.length; i++) {
        for (let j = 0; j < columnList.length; j++) {
            filterArr.push({
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                    table: tableList[i],
                    column: columnList[j].column
                },
                operator: "In",
                values: [columnList[j].propsName] ? [`${[columnList[j].propsName]}`] : []
            });
        }
    }
    console.log("powerbi filterArr ", filterArr);
     const renderSelectedTabPowerBi = (strategyName) => {
        let selectedreportId = "";
        detailsForAllStrategies.map((strategy) => {
            const strategyNameVal = strategyName.replace('Change In Sentiment', 'Change in Sentiment');
            if (strategy.strategyName.toUpperCase() === strategyNameVal.toUpperCase()) {
                selectedreportId = strategy.strategyReportId;
            }
        });
        setReportId(selectedreportId);  // This will trigger useEffect
        setRecommendationName(strategyName);
        setEmbedPBIToken('');  // Clear previous token        
    }
    
    const renderAllStrategies = () => {
        return activeRowForModel?.recommendationInfo.map((strategy, index) => {
            if (recommendationName === strategy.recommendationName) {
                return (<div className='recommendation-model-strategy-name active-strategy'>{strategy.recommendationName === 'Competitor Out Of Stock' ? 'Competition Out Of Stock' : strategy.recommendationName}</div>)
            }
            else {
                return (<div className='recommendation-model-strategy-name' onClick={(e) => { renderSelectedTabPowerBi(strategy.recommendationName) }}>{strategy.recommendationName === 'Competitor Out Of Stock' ? 'Competition Out Of Stock' : strategy.recommendationName}</div>)
            }

        })
    };
    return (
        <Modal size={'xl'} show={true} onHide={props.displayModalOff} id="recommendations-modal-new" >
            <Modal.Header closeButton id='new-recommendation-header-mar'>
                <div className='row new-recommendation-header-mar'>
                    <div className='col-12'>
                        {sosAlertname ?
                            <div className='recommendation-model-strategy-name active-strategy'>{recommendationName}</div>
                            : renderAllStrategies()}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="bg-gray py-4">

                <div className="row">
                    <div className="col-12 my-2">
                        {loading ? (
                            <div id="strategy_wrapper">
                                <div className="container-fluid  default-top loading_insights loading_wrapper bg-w p-5">
                                    <div className="sweet-loading">
                                        <span><b>Processing...</b></span>
                                        <BarLoader width={100} color="#123abc" />
                                    </div>
                                </div>
                            </div>) :
                            
                            <PowerBIEmbed
                        embedConfig={{
                            type: 'report',   // 'report' or 'dashboard'
                            id: reportId,
                            embedUrl: embedUrl,
                            accessToken: embedPBIToken,
                            tokenType: models.TokenType.Embed,
                            settings: extraSettings,
                            filters: filterArr 
                        }}
                        eventHandlers={
                            new Map([
                                ['loaded', (event) => {
                                    console.log('Report loaded', event);
                                    const report = event.target;
                                    if (report && typeof report.setFilters === 'function') {
                                        report.setFilters(filterArr).catch(function (errors) {
                                            console.error('Error setting filters:', errors);
                                        });
                                    } else {
                                        console.error('Report object does not support setFilters');
                                    }
                                }],
                                ['rendered', () => {
                                    console.log('Report rendered');
                                }]
                            ])
                        }
                        cssClassName="report-style-class"
                        style={reportStyle}
                    />
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )

}

export default NewRecommendationModal;